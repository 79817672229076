import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DropdownButton from '../../Components/DropdownButton/DropdownButton';

// platform images
import ebay from '../../Assets/Images/ebay_icon.svg';
import member from '../../Assets/Images/memberlogo.webp';

// Fallback for any listing or lot image that isn't available
const FALLBACK_IMAGE =
  'https://via.placeholder.com/300x200?text=No+Image+Available';

const EditListing = () => {
  const { lotId } = useParams();
  const navigate = useNavigate();

  const [listings, setListings] = useState([]);
  const [lot, setLot] = useState({});

  useEffect(() => {
    axiosInstance
      .post('/v1/crew/listing/get-listing-info', { lotId })
      .then((response) => {
        setListings(response.data.listings || []);
        setLot(response.data.lot || {});
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to fetch listing info.');
      });
  }, [lotId]);

  // Example: handle edit button click
  const handleEdit = (listingId) => {
    console.log('Edit clicked for listing:', listingId);
    toast.info(`Edit clicked for listing: ${listingId}`);
    // Or navigate to a route: navigate(`/dashboard/edit-listing/${listingId}`);
  };

  // Format price from integer (cents) to currency
  const formatPrice = (cents) => {
    if (!cents || isNaN(cents)) return '$0.00';
    return (cents / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  // Get the lot’s thumbnail
  const lotThumbnail = lot?.images?.[0] || FALLBACK_IMAGE;

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Edit Listing</h1>

      {/* Lot Details: image, title, condition */}
      <div className="mb-8 bg-gray-100 p-4 rounded-md flex gap-4 items-start">
        <img
          src={lotThumbnail}
          alt="Lot thumbnail"
          className="w-32 h-32 object-cover rounded"
        />
        <div>
          <h2 className="text-xl font-semibold">{lot?.title || 'N/A'}</h2>
          <p className="text-gray-700">
            <strong>Condition:</strong> {lot?.condition || 'N/A'}
          </p>
          {/* Add other lot fields if desired */}
        </div>
      </div>

      <h2 className='text-lg'>Platforms</h2>
        <div className="border-b border-gray-200 my-4"></div>
      {/* Listings Grid */}
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        
        {listings.map((listing) => {
          const displayPrice = formatPrice(listing.currentPrice);
          const views = listing.analytics?.views;
          // Listing image fallback
          const listingImage = listing.images?.[0] || FALLBACK_IMAGE;
          // Platform logo
          const platformLogo = listing.platform === 'Ebay' ? ebay : member;

          return (
            <div
              key={listing.id}
              className="flex flex-col bg-white ring-1 ring-gray-200 rounded-md shadow-sm overflow-hidden"
            >
              {/* Listing main image across the top */}
              {/* <img
                src={listingImage}
                alt="Listing thumbnail"
                className="w-full h-40 object-cover"
              /> */}

              <div className="p-4 flex flex-col flex-grow">
                <div className='flex py-4 w-full items-center justify-center'>
                <img
                    src={platformLogo}
                    alt={listing.platform}
                    className="h-12 " // h-auto avoids squish, w-auto keeps proportions
                  />
                </div>
                {/* Price */}
                <p className="text-lg font-semibold text-gray-800 mb-1">
                  {displayPrice}
                </p>

                {/* Status */}
                <p className="text-sm text-gray-600 mb-1">
                  Status: {listing.status}
                </p>

                {/* type */}
                <p className="text-sm text-gray-600 mb-1">
                  {listing.type}
                </p>

                {/* Views (if available) */}
                {typeof views !== 'undefined' && (
                  <p className="text-sm text-gray-600 mb-1">Views: {views}</p>
                )}

                {/* Links and buttons */}
                <div className="mt-auto flex items-center justify-between pt-4">
                  {/* “View” link (external or internal) */}
                  {listing.view && (
                    <a
                      href={listing.view}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline text-sm"
                    >
                      View
                    </a>
                  )}

                  
                </div>
                <DropdownButton 
                    text="Edit" 
                    size="sm" 
                    onClick={() => alert('open platform listing modal editor')}
                    className="flex w-full items-center justify-center gap-x-1.5 bg-indigo-600 text-white font-semibold shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md" 
                    dropdownOptions={[
                        { label: 'End Listing', onClick: () => alert('end listing') },
                    ]}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EditListing;
