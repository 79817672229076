import React, { useEffect, useState, useMemo, useCallback, useRef, Fragment } from 'react';
import axiosInstance from '../../axiosInstance';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import Chip from '../../Components/Chip/Chip'; // Use Chip for status
import Button from '../../Components/Button/Button';
import PaymentModal from '../../Components/PaymentModal/PaymentModal';
import DropdownButton from '../../Components/DropdownButton/DropdownButton';
import { Search, X } from 'lucide-react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

// Utility for combining class names (from your example)
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Define your status options
const statuses = [
  { label: 'All Statuses', value: '' },
  { label: 'Awaiting Payment', value: 'Awaiting Payment' },
  { label: 'Paid', value: 'Paid' },
  { label: 'Completed', value: 'Completed' },
  { label: 'Cancelled', value: 'Cancelled' },
];

// Define your platform options
const platforms = [
  { label: 'All Platforms', value: '' },
  { label: 'Ebay', value: 'Ebay' },
  { label: 'Member', value: 'Member' },
];

export default function ViewOrders() {
  const navigate = useNavigate();

  // Payment modal
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentOrderId, setPaymentOrderId] = useState(null);

  // URL search params
  const [searchParams, setSearchParams] = useSearchParams();

  // Sort
  const [sort, setSort] = useState(() => {
    const sortParam = searchParams.get('sort');
    try {
      return sortParam ? JSON.parse(sortParam) : { createdAt: -1 };
    } catch (e) {
      console.error('Failed to parse sort param:', e);
      return { createdAt: -1 };
    }
  });

  // Filters: includes both status + platform
  const [filters, setFilters] = useState(() => {
    const statusParam = searchParams.get('status') || '';
    const platformParam = searchParams.get('platform') || '';
    return { status: statusParam, platform: platformParam };
  });

  // For the Listbox components, find matching "selected" objects from statuses/platforms:
  const selectedStatusObj = statuses.find((s) => s.value === filters.status) || statuses[0];
  const selectedPlatformObj = platforms.find((p) => p.value === filters.platform) || platforms[0];

  // Pagination
  const [page, setPage] = useState(() => {
    const pageParam = parseInt(searchParams.get('page'), 10);
    return !isNaN(pageParam) && pageParam > 0 ? pageParam - 1 : 0;
  });
  const [pageSize, setPageSize] = useState(() => {
    const pageSizeParam = parseInt(searchParams.get('pageSize'), 10);
    return !isNaN(pageSizeParam) ? pageSizeParam : 25;
  });

  // We separate local text (for the input) from the actual searchText used to query.
  const [localSearchText, setLocalSearchText] = useState(
    () => searchParams.get('searchText') || ''
  );
  const [searchText, setSearchText] = useState(localSearchText);

  // Data
  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const isMounted = useRef(true);

  // Update URL parameters whenever our "real" query states change
  useEffect(() => {
    const params = new URLSearchParams();
    const sortParam = JSON.stringify(sort);
    params.set('sort', sortParam);

    if (filters.status) params.set('status', filters.status);
    else params.delete('status');

    if (filters.platform) params.set('platform', filters.platform);
    else params.delete('platform');

    params.set('page', (page + 1).toString());
    params.set('pageSize', pageSize.toString());

    if (searchText) {
      params.set('searchText', searchText);
    } else {
      params.delete('searchText');
    }

    setSearchParams(params, { replace: true });
  }, [sort, filters, page, pageSize, searchText, setSearchParams]);

  // Fetch data from API
  const fetchData = useCallback(async () => {
    setLoading(true);
    const offset = page * pageSize;
    const count = pageSize;

    try {
      const response = await axiosInstance.post('v1/crew/order/keyword-search', {
        keyword: searchText,
        offset,
        count,
        sort,
        filters,
      });
      console.log(response);

      if (response.status === 200) {
        const formattedData = response.data.results.map((order) => ({
          id: order._id,
          buyerName: order.shippingAddress?.name || 'Unknown',
          street1: order.shippingAddress?.address?.street1 || '',
          street2: order.shippingAddress?.address?.street2 || '',
          city: order.shippingAddress?.address?.city || '',
          state: order.shippingAddress?.address?.state || '',
          zip: order.shippingAddress?.address?.zip || '',
          status: order.status,
          createdAt: order.createdAt,
          platform: order.platform,
          orderCode: order.orderCode,
          total: ((order.orderLedger?.orderTotal) / 100).toFixed(2) || '0.00',
        }));
        if (isMounted.current) {
          setRowData(formattedData);
          setTotalCount(response.data.totalSearchCount);
        }
      } else {
        if (isMounted.current) setRowData([]);
      }
    } catch (error) {
      if (isMounted.current) setRowData([]);
      console.error('Error fetching data:', error);
    } finally {
      if (isMounted.current) setLoading(false);
    }
  }, [page, pageSize, searchText, sort, filters]);

  // Fetch data on component mount and when dependencies change
  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [fetchData]);

  // Render status with Chip component
  const renderStatus = (status) => {
    switch (status) {
      case 'Awaiting Payment':
        return (
          <div className="flex items-center h-full">
            <Chip text={status} color="red" />
          </div>
        );
      case 'Paid':
        return (
          <div className="flex items-center h-full">
            <Chip text={status} color="green" />
          </div>
        );
      case 'Completed':
        return (
          <div className="flex items-center h-full">
            <Chip text={status} color="blue" />
          </div>
        );
      default:
        return status;
    }
  };

  // Define column definitions
  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Name/Address',
        field: 'buyerName',
        minWidth: 150,
        flex: 2,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        cellRenderer: (params) => {
          return (
            <div
              style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
              className="flex flex-col w-full py-2"
            >
              <p className="leading-tight font-bold">{params.value}</p>
              <p className="leading-tight">{params.data.street1}</p>
              {params.data.street2 && <p className="leading-tight">{params.data.street2}</p>}
              <p className="leading-tight">
                {params.data.city}, {params.data.state} {params.data.zip}
              </p>
            </div>
          );
        },
      },
      {
        headerName: 'Pickup Code',
        field: 'orderCode',
        flex: 1,
        sortable: true,
        cellRenderer: (params) => {
          return (
            <div className="flex flex-col justify-center items-center h-full w-full py-2 blur hover:blur-none focus:blur-none">
              <p className="leading-tight font-bold">{params.value}</p>
            </div>
          );
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        flex: 1,
        minWidth: 190,
        sortable: true,
        cellRenderer: (params) => renderStatus(params.value),
      },
      {
        headerName: 'Created At',
        field: 'createdAt',
        flex: 1,
        sortable: true,
        valueFormatter: (params) =>
          new Date(params.value).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }),
      },
      {
        headerName: 'Platform',
        field: 'platform',
        flex: 1,
        sortable: true,
      },
      {
        headerName: 'Total',
        field: 'total',
        flex: 1,
        sortable: true,
        valueFormatter: (params) => `$${params.value}`,
      },
      {
        headerName: 'Actions',
        field: 'actions',
        sortable: false,
        cellStyle: { overflow: 'visible' },
        cellRenderer: (params) => (
          <div className="flex items-center justify-end h-full">
            {params.data.status === 'Completed' && (
              <DropdownButton
                text="View"
                size="sm"
                onClick={() => navigate(`/Dashboard/Orders/Edit/${params.data.id}`)}
                className="flex items-center justify-center bg-indigo-600 text-white font-semibold shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
                dropdownOptions={[
                  { label: 'Cancel Order', onClick: () => alert('navigate to cancel order') },
                ]}
              />
            )}
            {params.data.status === 'Awaiting Payment' && (
              <DropdownButton
                text="Checkout"
                size="sm"
                onClick={() => {
                  setPaymentModalOpen(true);
                  setPaymentOrderId(params.data.id);
                }}
                className="flex items-center justify-center bg-indigo-600 text-white font-semibold shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
                dropdownOptions={[
                  { label: 'View & Edit', onClick: () => navigate(`/Dashboard/Orders/Edit/${params.data.id}`) },
                ]}
              />
            )}
            {params.data.status === 'Paid' && (
              <DropdownButton
                text="Pick"
                size="sm"
                onClick={() => alert('navigate to edit listing screen')}
                className="flex items-center justify-center bg-indigo-600 text-white font-semibold shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
                dropdownOptions={[
                  { label: 'View & Edit', onClick: () => navigate(`/Dashboard/Orders/Edit/${params.data.id}`) },
                ]}
              />
            )}
          </div>
        ),
      },
    ],
    [navigate, renderStatus]
  );

  // Define grid options
  const gridOptions = useMemo(
    () => ({
      columnDefs,
      rowData,
      pagination: false,
      domLayout: 'autoHeight',
      suppressRowTransform: true,
      suppressSorting: true,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
      overlayNoRowsTemplate: loading
        ? '<span class="ag-overlay-loading-center">Loading...</span>'
        : '<span class="ag-overlay-no-rows-center">No orders found</span>',
      onSortChanged: (params) => {
        console.log('Sort changed:', params.columns[0].sort);
        let sort = params.columns[0].sort;

        if (sort) {
          if (sort === 'asc') {
            setSort({ [params.columnApi.getAllColumns()[params.columnIndex].colId]: 1 });
          } else if (sort === 'desc') {
            setSort({ [params.columnApi.getAllColumns()[params.columnIndex].colId]: -1 });
          }
        }
        setPage(0); // Reset to first page on sort
      },
      defaultColDef: {
        resizable: true,
        wrapText: true,
      },
    }),
    [columnDefs, rowData, loading, setSort]
  );

  // Handle search button
  const handleSearch = () => {
    setSearchText(localSearchText);
    setPage(0);
  };

  // Handle clear search
  const handleClearSearch = () => {
    setLocalSearchText('');
    setSearchText('');
    setPage(0);
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  // Render your custom Listbox for status
  function renderStatusListbox() {
    return (
      <Listbox
        value={selectedStatusObj}
        onChange={(selectedObj) => {
          setFilters((prev) => ({ ...prev, status: selectedObj.value }));
          setPage(0);
        }}
      >
        {({ open }) => (
          <div className="relative">
            <Listbox.Button className="relative w-44 cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none sm:text-sm">
              <span className="block truncate">{selectedStatusObj.label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-44 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {statuses.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-indigo-600' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    );
  }

  // Render your custom Listbox for platform
  function renderPlatformListbox() {
    return (
      <Listbox
        value={selectedPlatformObj}
        onChange={(selectedObj) => {
          setFilters((prev) => ({ ...prev, platform: selectedObj.value }));
          setPage(0);
        }}
      >
        {({ open }) => (
          <div className="relative">
            <Listbox.Button className="relative w-44 cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none sm:text-sm">
              <span className="block truncate">{selectedPlatformObj.label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-44 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {platforms.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-indigo-600' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    );
  }

  return (
    <div className="flex flex-col h-full bg-gray-50 min-h-screen">

      {/* Payment Modal */}
      <PaymentModal
        isOpen={paymentModalOpen}
        onClose={() => setPaymentModalOpen(false)}
        orderId={paymentOrderId}
      />

      {/* Header Section */}
      <div className="bg-white shadow border-b p-6">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Orders
            </h2>
          </div>
          <div className="mt-5 flex flex-wrap lg:ml-4 lg:mt-0 space-x-4 items-center">
            {/* Status Filter (Listbox) */}
            {renderStatusListbox()}

            {/* Platform Filter (Listbox) */}
            {renderPlatformListbox()}

            {/* Search Input (localSearchText) */}
            <div className="relative">
              <input
                type="text"
                value={localSearchText}
                onChange={(e) => setLocalSearchText(e.target.value)}
                placeholder="Search orders"
                className="block w-full rounded-md border-gray-300 pl-10 pr-3 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
            </div>

            {/* Search and Clear Buttons */}
            <Button onClick={handleSearch} variant="primary">
              Search
            </Button>
            <Button onClick={handleClearSearch} variant="secondary">
              <X className="h-5 w-5" />
            </Button>
          </div>
        </div>
      </div>

      {/* Orders Grid Section */}
      <div className="flex flex-col flex-1">
        <div
          className="ag-theme-material w-full"
          style={{ flex: '1 1 auto', overflow: 'visible' }}
        >
          <AgGridReact {...gridOptions} />
        </div>
        {loading && <Loading />}

        {/* Pagination Controls */}
        <div className="flex justify-between items-center p-3">
          <span className="text-sm text-gray-700">
            Page {page + 1} of {totalPages}
          </span>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setPage(Math.max(page - 1, 0))}
              disabled={page === 0}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page === 0
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500'
              }`}
            >
              Previous
            </button>
            <button
              onClick={() => setPage(Math.min(page + 1, totalPages - 1))}
              disabled={page + 1 >= totalPages}
              className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                page + 1 >= totalPages
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-indigo-600 hover:bg-indigo-500'
              }`}
            >
              Next
            </button>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              className="rounded-md border border-gray-300 bg-white py-2 px-3 pr-8 text-sm shadow-sm"
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  {size} / page
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
