import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../Components/Button/Button";
import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import CurrencyInput from "react-currency-input-field";

// Utility for combining class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Payment method options
const paymentMethodOptions = [
  { label: "Cash", value: "Cash" },
  { label: "Credit Card", value: "Stripe" },
];

export default function PaymentModal({ isOpen, onClose = () => {}, orderId }) {
  // We'll store amount as a string from the currency input
  const [amount, setAmount] = useState("");
  const [method, setMethod] = useState("Credit Card");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const closeModal = () => {
    onClose(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // parseFloat the user’s typed string (e.g. "12.99" -> 12.99)
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }

    // Convert USD to cents
    const amountInCents = Math.round(parsedAmount * 100);

    setIsSubmitting(true);

    try {
      await axiosInstance.post(`/v1/orders/${orderId}/add-payment`, {
        amount: amountInCents,
        method, // "Cash" or "Stripe"
      });
      toast.success("Payment added successfully!");
      onClose(false);
    } catch (error) {
      toast.error(error.response?.data || "Failed to add payment.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Find the selected option object from `paymentMethodOptions`
  const selectedMethodObj =
    paymentMethodOptions.find((opt) => opt.label === method) ||
    paymentMethodOptions[0];

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          {/* Trick browser into centering the modal. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left
                align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Add Payment for Order #{orderId}
              </Dialog.Title>

              <form onSubmit={handleSubmit} className="mt-4">
                {/* Amount Field using CurrencyInput */}
                <div className="mb-4">
                  <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                    Amount
                  </label>
                  <CurrencyInput
                    id="amount"
                    name="amount"
                    prefix="$"
                    placeholder="Enter amount"
                    decimalsLimit={2}
                    value={amount}
                    onValueChange={(value) => setAmount(value || "")}
                    className="mt-1 block w-full border border-gray-300 rounded-md
                      shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    required
                  />
                </div>

                {/* Payment Method with Listbox */}
                <div className="mb-4">
                  <label htmlFor="method" className="block text-sm font-medium text-gray-700">
                    Payment Method
                  </label>
                  <Listbox
                    value={selectedMethodObj}
                    onChange={(selectedObj) => setMethod(selectedObj.label)}
                  >
                    {({ open }) => (
                      <div className="relative mt-1">
                        <Listbox.Button
                          className="relative w-full cursor-default rounded-md
                            border border-gray-300 bg-white py-2 pl-3 pr-10 text-left
                            shadow-sm focus:outline-none sm:text-sm"
                        >
                          <span className="block truncate">{selectedMethodObj.label}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto
                              rounded-md bg-white py-1 text-base shadow-lg
                              ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            {paymentMethodOptions.map((option) => (
                              <Listbox.Option
                                key={option.value}
                                className={({ active }) =>
                                  classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={option}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected ? "font-semibold" : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {option.label}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={classNames(
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                          active ? "text-indigo-600" : "text-indigo-600"
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    )}
                  </Listbox>
                </div>

                {/* Submit / Cancel */}
                <div className="flex justify-end">
                  <Button
                    variant="secondary"
                    onClick={closeModal}
                    className="mr-2"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Submit Payment"}
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
