import React, { useState, useEffect, useRef, useCallback } from 'react';
import axiosInstance from '../../axiosInstance';
import TextInput from '../TextInput/TextInput';
import { toast } from 'react-toastify';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { BarLoader } from 'react-spinners';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import DateTimeInput from '../DateTimeInput/DateTimeInput';
import Toggle from '../Toggle/Toggle';
import Button from '../Button/Button';
import { Box, Tag, LocateFixed } from 'lucide-react';

dayjs.extend(relativeTime);


const LotStatistic = ({ className = "", label = "Lots" }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!loading) {
            setLoading(true);
        }
        axiosInstance.post(`/v1/crew/lot/get-lots-count`, {status: ["Active", "Idle", "Sold", "Completed"], source: [], condition: [], category: [], crew: [], tags: []}).then((response) => {
            setData(response.data);
            console.log(response.data);
            setLoading(false);
        }).catch((error) => {
            setError(error.response?.data?.error || 'Failed to fetch data');
            setLoading(false);
        });
    }, []);
    
    if (loading) {
        return (
            <div className={"flex min-h-32 items-center p-4 bg-white gap-8 rounded-lg" + " "+className}>
                <BarLoader color='#2563EB' width={"100%"} />
            </div>
        );
    }

    if (error) {
        return (
            <div className={"flex min-h-32 p-4 bg-white gap-8 rounded-lg" + " "+className}>
                <p className='text-red-500'>{error}</p>
            </div>
        );
    }

    return (
        <div className={"flex flex-col min-h-32 p-4 bg-white shadow rounded-lg" + " "+className}>
            <p className='text-lg leading-tight font-semibold'>{label}</p>
            <div className='flex gap-4 flex-wrap justify-around'>
                <div className='flex flex-col'>
                    <p className='text-md text-gray-500'>Idle</p>
                    <div className='flex gap-2 items-center'>
                        {/* <Box size='32' /> */}
                        <p className='text-5xl font-semibold'>{data?.breakdown.statuses.Idle.toLocaleString()}</p>
                    </div>
                    
                </div>
                <div className='flex flex-col'>
                    <p className='text-md text-gray-500'>Active</p>
                    <div className='flex gap-2 items-center'>
                        {/* <Tag size='32' /> */}
                        <p className='text-5xl font-semibold'>{data?.breakdown.statuses.Active.toLocaleString()}</p>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <p className='text-md text-gray-500'>Sold</p>
                    <div className='flex gap-2 items-center'>
                        {/* <Tag size='32' /> */}
                        <p className='text-5xl font-semibold'>{data?.breakdown.statuses.Sold.toLocaleString()}</p>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <p className='text-md text-gray-500'>Completed / Picked</p>
                    <div className='flex gap-2 items-center'>
                        {/* <LocateFixed size='32' /> */}
                        <p className='text-5xl font-semibold'>{data?.breakdown.statuses.Completed.toLocaleString()}</p>
                    </div>
                    
                </div>

            </div>
        </div>
    );

};

export default LotStatistic;