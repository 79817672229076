import React, { useState, useEffect, useRef, useCallback } from 'react';
import axiosInstance from '../../axiosInstance';
import TextInput from '../TextInput/TextInput';
import { toast } from 'react-toastify';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { BarLoader } from 'react-spinners';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import DateTimeInput from '../DateTimeInput/DateTimeInput';
import Toggle from '../Toggle/Toggle';
import Button from '../Button/Button';
import { Box, Tag, LocateFixed } from 'lucide-react';

dayjs.extend(relativeTime);


const ListingStatistic = ({ className = "", label = "Lots & Listings", timeFrame }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!loading) {
            setLoading(true);
        }
        axiosInstance.get(`/v1/crew/member/listing-statistics/${timeFrame}`).then((response) => {
            setData(response.data);
            console.log(response.data);
            setLoading(false);
        }).catch((error) => {
            setError(error.response?.data?.error || 'Failed to fetch data');
            setLoading(false);
        });
    }, [timeFrame]);
    
    if (loading) {
        return (
            <div className={"flex min-h-32 items-center p-4 bg-white gap-8 rounded-lg" + " "+className}>
                <BarLoader color='#2563EB' width={"100%"} />
            </div>
        );
    }

    if (error) {
        return (
            <div className={"flex min-h-32 p-4 bg-white gap-8 rounded-lg" + " "+className}>
                <p className='text-red-500'>{error}</p>
            </div>
        );
    }

    return (
        <div className={"flex flex-col min-h-32 p-4 bg-white shadow gap-4 rounded-lg" + " "+className}>
            <p className='text-lg leading-tight font-semibold'>{label}</p>
            <div className='flex gap-4 flex-wrap justify-around'>
                <div className='flex flex-col'>
                    <p className='text-md text-gray-500'>Lots Created</p>
                    <div className='flex gap-2 items-center'>
                        <Box size='32' />
                        <p className='text-5xl font-semibold'>{data?.lotCount.toLocaleString()}</p>
                    </div>
                    
                </div>
                <div className='flex flex-col'>
                    <p className='text-md text-gray-500'>Lots Listed</p>
                    <div className='flex gap-2 items-center'>
                        <Tag size='32' />
                        <p className='text-5xl font-semibold'>{data?.lotsListed.toLocaleString()}</p>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <p className='text-md text-gray-500'>Lots Located</p>
                    <div className='flex gap-2 items-center'>
                        <LocateFixed size='32' />
                        <p className='text-5xl font-semibold'>{data?.locationCount.toLocaleString()}</p>
                    </div>
                    
                </div>

            </div>
            {/* breakdown table by crew member */}
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                            <span>Crew Member Breakdown</span>
                            <ChevronUpIcon
                                className={`${
                                    open ? 'transform rotate-180' : ''
                                } w-5 h-5 text-blue-500`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        <th className="text-left">Crew Member</th>
                                        <th className="text-left">Lots Created</th>
                                        <th className="text-left">Lots Listed</th>
                                        <th className="text-left">Lots Located</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {data?.crewMembers.map((member) => (
                                        <tr key={member.id}>
                                            <td>{member.name}</td>
                                            <td>{member.lotCount}</td>
                                            <td>{member.lotsListed}</td>
                                            <td>{member.locationCount}</td>
                                        </tr>
                                    ))} */}
                                </tbody>
                            </table>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );

};

export default ListingStatistic;