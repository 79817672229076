import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import { Disclosure } from '@headlessui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BarLoader } from 'react-spinners';
import Drawer from '../../Components/Drawer/Drawer';
import {
  CheckIcon,
  ChevronDownIcon,
  MapPinIcon,
  ArrowUturnDownIcon,
  SparklesIcon,
} from '@heroicons/react/20/solid';

import TextInput from '../../Components/TextInput/TextInput';
import TextArea from '../../Components/TextArea/TextArea';
import ConditionSelect from '../../Components/ConditionSelect/ConditionSelect';
import TagSelect from '../../Components/TagSelect/TagSelect';
import ImageUpload from '../../Components/ImageUpload/ImageUpload';
import ListItemModal from '../../Components/ListItem/ListItemModal';
import ListItem from '../../Components/ListItem/ListItem';
import { Divider } from '@material-ui/core';

export default function EditLot() {
  const navigate = useNavigate();
  const { lotId } = useParams();
  const [lotInfo, setLotInfo] = useState(null);
  const [editedLotInfo, setEditedLotInfo] = useState(null);
  const [preAiInfo, setPreAiInfo] = useState(null);
  const [gptStatus, setGptStatus] = useState(false);
  const [listModelOpen, setListModelOpen] = useState(false);
  const isMounted = useRef(true); // To prevent memory leaks

  useEffect(() => {
    isMounted.current = true; // Component is mounted
    const fetchLotInfo = async () => {
      try {
        const response = await axiosInstance.get('/v1/crew/lot/fullInfo', {
          params: { lotId: lotId },
        });
        const lot = response.data.lot;
        lot.conditionDescription = lot.condition.conditionDescription;
        if (isMounted.current) {
          setLotInfo(lot);
          setEditedLotInfo(lot);
          setPreAiInfo(lot);
          document.title = `${lot.lotNumber} - ${lot.title}`; // Use the fetched lot object
        }
      } catch (error) {
        console.log('Error fetching lot info:', error);
        if (isMounted.current) {
          toast.error('Error fetching lot information.');
        }
      }
    };

    fetchLotInfo();

    return () => {
      isMounted.current = false; // Cleanup on unmount
    };
  }, [lotId]);

  if (!lotInfo) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-xl">Loading...</p>
      </div>
    );
  }

  async function handleEditSubmit() {
    try {
      const response = await axiosInstance.put('/v1/crew/lot/edit/' + lotId, {
        title: editedLotInfo.title,
        description: editedLotInfo.description,
        lotNumber: editedLotInfo.lotNumber,
        tags: editedLotInfo.tags,
        condition: editedLotInfo.condition.id,
        conditionDescription: editedLotInfo.conditionDescription,
        images: editedLotInfo.images,
        details: editedLotInfo.details,
      });

      toast.success('Lot details updated successfully!');

      // Navigate back after success
      // navigate(-1);

      return response;
    } catch (error) {
      console.log(error);
      toast.error('Failed to submit lot information.');
      throw error;
    }
  }

  async function chatgptFix() {
    setGptStatus('Loading');
    try {
      const response = await axiosInstance.post('/v1/crew/lot/chatgpt-fix', {
        itemTitle: editedLotInfo.title,
        itemDescription: editedLotInfo.description,
        itemImages: editedLotInfo.images,
        details: editedLotInfo.details, // Send the entire details object
      });
      console.log(response.data);

      const { itemTitle, itemDescription, details } = response.data;

      // Save the pre-AI state
      setPreAiInfo((prevState) => ({
        ...prevState,
        title: lotInfo.title,
        description: lotInfo.description,
        details: {
          ...prevState.details,
          ...lotInfo.details,
        },
      }));

      // Update the edited lot info with AI-generated data
      setEditedLotInfo((prevState) => ({
        ...prevState,
        title: itemTitle,
        description: itemDescription,
        details: {
          ...prevState.details,
          ...details, // Merge AI details
        },
      }));

      setGptStatus('Complete');
      toast.success('AI completion successful!');
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      setGptStatus(false);
      toast.error('AI completion failed.');
      throw error;
    }
  }

  return (
    <div className="mx-auto p-3">
      <Drawer
        // lotData={editedLotInfo}
        onClose={() => setListModelOpen(false)}
        title="List Now"
        open={listModelOpen}
      >
        <ListItem typeTabs displayLot={true} lotId={lotId}/>
      </Drawer>
      <div className="md:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1 mb-3">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Edit Lot
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <MapPinIcon
                aria-hidden="true"
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              />
              {lotInfo.locationHistory[0]
                ? lotInfo.locationHistory[0].label
                : 'Not Located'}
            </div>

            {editedLotInfo.tags.length > 0 && (
              <div className="mt-2 flex items-center text-sm gap-1 text-gray-500">
                {editedLotInfo.tags.map((tag) => (
                  <div
                    key={tag._id}
                    style={{
                      color: tag.color,
                      backgroundColor: tag.backgroundColor,
                    }}
                    className="rounded-full px-2 text-xs flex items-center h-full"
                  >
                    {tag.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="mt-5 flex pb-3 md:pb-0 lg:ml-4 lg:mt-0">
          <span>
            {gptStatus === 'Complete' ? (
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => {
                  setEditedLotInfo((prevState) => ({
                    ...prevState,
                    title: preAiInfo.title,
                    description: preAiInfo.description,
                    details: {
                      ...prevState.details,
                      ...preAiInfo.details, // Revert to pre-AI details
                    },
                  }));
                  setGptStatus(false);
                  toast.info('AI changes undone.');
                }}
              >
                <ArrowUturnDownIcon
                  aria-hidden="true"
                  className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                />
                Undo AI
              </button>
            ) : (
              <button
                disabled={gptStatus === 'Loading'}
                type="button"
                className="inline-flex items-center mr-3 gap-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => chatgptFix()}
              >
                <SparklesIcon
                  aria-hidden="true"
                  className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                />
                {gptStatus === 'Loading' ? (
                  <BarLoader color="gray" loading={true} size={24} />
                ) : (
                  <>AI Completion</>
                )}
              </button>
            )}
          </span>

          {/* list now button */}

          <span>
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                setListModelOpen(true);
              }}
            >
              List & Submit
            </button>
          </span>

          <span className="ml-3">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={(e) => {
                e.preventDefault();
                handleEditSubmit();
              }}
            >
              <CheckIcon
                aria-hidden="true"
                className="-ml-0.5 mr-1.5 h-5 w-5"
              />
              Submit
            </button>
          </span>
        </div>
      </div>

      <form className="space-y-3">
        <ImageUpload
          preUploadedImages={lotInfo.images}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              images: e,
            }))
          }
        />

        <div className="flex gap-3 w-full">
          <TextInput
            id="lotNumber"
            type="number"
            label="Lot Number"
            value={editedLotInfo.lotNumber}
            onChange={(e) =>
              setEditedLotInfo((prevState) => ({
                ...prevState,
                lotNumber: parseInt(e.target.value, 10),
              }))
            }
            placeholder="Enter Lot Number"
            required
          />
          <TextInput
            id="title"
            label="Title"
            containerClassName="w-full"
            value={editedLotInfo.title}
            onChange={(e) => {
              if (e.target.value.length <= 80) {
                setEditedLotInfo((prevState) => ({
                  ...prevState,
                  title: e.target.value,
                }));
              }
            }}
            suffix={`${editedLotInfo.title.length}/80`}
            placeholder="Enter Lot Title"
            required
          />
        </div>

        <TextArea
          id="description"
          value={editedLotInfo.description}
          label="Description"
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              description: e.target.value,
            }))
          }
          placeholder="Enter Lot Description"
          multiline
          rows={4}
          required
        />

<Disclosure defaultOpen>
  {({ open }) => (
    <div className='ring-1 ring-gray-200 rounded-md'>
      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
        <span>Lot Details</span>
        <ChevronDownIcon
          className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
        />
      </Disclosure.Button>
      <Disclosure.Panel className="grid grid-cols-2 gap-2 px-4 pt-4 pb-2 text-sm text-gray-500">
        {/* UPC */}
        <TextInput
          id="upc"
          label="UPC"
          type="text"
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.upc || '');
                  toast.info('UPC copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(
                  editedLotInfo.details?.upc || ''
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Search
              </a>
            </div>
          }
          value={editedLotInfo.details?.upc || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                upc: e.target.value,
              },
            }))
          }
          placeholder="Enter UPC"
        />

        {/* Model */}
        <TextInput
          id="model"
          label="Model"
          type="text"
          value={editedLotInfo.details?.model || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                model: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.model || '');
                  toast.info('Model copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(
                  editedLotInfo.details?.model || ''
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Search
              </a>
            </div>
          }
          placeholder="Enter Model"
        />

        {/* Brand */}
        <TextInput
          id="brand"
          label="Brand"
          type="text"
          value={editedLotInfo.details?.brand || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                brand: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.brand || '');
                  toast.info('Brand copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(
                  editedLotInfo.details?.brand || ''
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Search
              </a>
            </div>
          }
          placeholder="Enter Brand"
        />

        {/* MPN */}
        <TextInput
          id="mpn"
          label="MPN"
          type="text"
          value={editedLotInfo.details?.mpn || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                mpn: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.mpn || '');
                  toast.info('MPN copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(
                  editedLotInfo.details?.mpn || ''
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Search
              </a>
            </div>
          }
          placeholder="Enter MPN"
        />

        {/* Type */}
        <TextInput
          id="type"
          label="Type"
          type="text"
          value={editedLotInfo.details?.type || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                type: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.type || '');
                  toast.info('Type copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(
                  editedLotInfo.details?.type || ''
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Search
              </a>
            </div>
          }
          placeholder="Enter Type"
        />

        {/* Material */}
        <TextInput
          id="material"
          label="Material"
          type="text"
          value={editedLotInfo.details?.material || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                material: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.material || '');
                  toast.info('Material copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(
                  editedLotInfo.details?.material || ''
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Search
              </a>
            </div>
          }
          placeholder="Enter Material"
        />

        {/* Color */}
        <TextInput
          id="color"
          label="Color"
          type="text"
          value={editedLotInfo.details?.color || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                color: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.color || '');
                  toast.info('Color copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(
                  editedLotInfo.details?.color || ''
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Search
              </a>
            </div>
          }
          placeholder="Enter Color"
        />

        {/* Size */}
        <TextInput
          id="size"
          label="Size"
          type="text"
          value={editedLotInfo.details?.size || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                size: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.size || '');
                  toast.info('Size copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
            </div>
          }
          placeholder="Enter Size"
        />

        {/* Height */}
        <TextInput
          id="height"
          label="Height"
          type="text"
          value={editedLotInfo.details?.height || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                height: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.height || '');
                  toast.info('Height copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
            </div>
          }
          placeholder="Enter Height"
        />

        {/* Width */}
        <TextInput
          id="width"
          label="Width"
          type="text"
          value={editedLotInfo.details?.width || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                width: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.width || '');
                  toast.info('Width copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
            </div>
          }
          placeholder="Enter Width"
        />

        {/* Depth */}
        <TextInput
          id="depth"
          label="Depth"
          type="text"
          value={editedLotInfo.details?.depth || ''}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              details: {
                ...prevState.details,
                depth: e.target.value,
              },
            }))
          }
          suffix={
            <div className="flex space-x-2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(editedLotInfo.details?.depth || '');
                  toast.info('Depth copied to clipboard!');
                }}
                className="cursor-pointer"
              >
                Copy
              </a>
            </div>
          }
          placeholder="Enter Depth"
        />
      </Disclosure.Panel>
    </div>
  )}
</Disclosure>


        <ConditionSelect
          label="Condition"
          selectedOption={lotInfo.condition}
          onChange={(e) =>
            setEditedLotInfo((prevState) => ({
              ...prevState,
              condition: {
                value: e.value,
                id: e.id,
                name: e.name,
                useNotes: e.useNotes,
              },
            }))
          }
        />

        {editedLotInfo.condition.useNotes && (
          <TextArea
            label="Condition Description"
            value={editedLotInfo.conditionDescription}
            onChange={(e) =>
              setEditedLotInfo((prevState) => ({
                ...prevState,
                conditionDescription: e.target.value,
              }))
            }
          />
        )}

        <TagSelect
          selectedTags={editedLotInfo.tags}
          label="Tags"
          onChange={(e) => {
            setEditedLotInfo((prevState) => ({
              ...prevState,
              tags: e,
            }));
          }}
        />
      </form>

      {/* Location History */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Location History
        </label>
        <div className="mt-2 space-x-2 flex">
          {lotInfo.locationHistory && lotInfo.locationHistory.length > 0 ? (
            lotInfo.locationHistory.map((location, index) => (
              <div
                key={index}
                className="p-2 border border-gray-300 rounded-md bg-gray-50"
              >
                <div className="flex justify-between w-full">
                  <p className="font-bold">{location.label || 'N/A Label'}</p>
                  {index === 0 && <p className="text-green-500 font-bold">Current</p>}
                </div>
                <p className="text-gray-400">
                  {new Date(location.createdAt).toLocaleString()}
                </p>
              </div>
            ))
          ) : (
            <span className="text-gray-500">No Location History Available</span>
          )}
        </div>
      </div>

      {/* Source Information */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Source Information
        </label>
        <div className="mt-1 p-4 border border-gray-300 rounded-md bg-gray-50">
          {lotInfo.source ? (
            <div className="space-y-2">
              <p>
                <strong>Name (Title):</strong> {lotInfo.source.name}
              </p>
              {lotInfo.source.orderedAt && (
                <p>
                  <strong>Ordered At:</strong>{' '}
                  {new Date(lotInfo.source.orderedAt).toLocaleString()}
                </p>
              )}
              {lotInfo.source.createdAt && (
                <p>
                  <strong>Created At:</strong>{' '}
                  {new Date(lotInfo.source.createdAt).toLocaleString()}
                </p>
              )}
            </div>
          ) : (
            <span className="text-gray-500">No Source Information Available</span>
          )}
        </div>
      </div>
    </div>
  );
}
