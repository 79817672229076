import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ViewOrders from './ViewOrders';
import EditOrder from './EditOrder';


export default function Orders() {
    return (
        <Routes>
            <Route path='/' element={<ViewOrders />} />
            <Route path='/Edit/:orderId' element={<EditOrder />} />
            

        </Routes>
    );
}
